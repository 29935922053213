import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import AuthMethodPicker from '../authentication/AuthMethodPicker';

const layout = (content : any) => (<Container fluid={true} className="p-3 layout">
                        <Navbar bg="dark" variant="dark" className={"px-3"}>
                            <Navbar.Brand>
                                <img alt="" src="/exite.svg" height="32" className="d-none d-md-inline-block align-top"/>
                                <img alt="" src="/exitex.svg" height="32" className="d-inline-block d-md-none align-top"/>
                                &nbsp;WebEDI
                            </Navbar.Brand>
                        </Navbar>
                        <div className={"jumbotron"}>{content}</div>
                        </Container>);

interface ExpiredSessionState {
    picker : boolean;
}

export class ExpiredSession extends React.Component<{}, ExpiredSessionState> {

    constructor(props: any) {
		super(props);
		this.state = {picker: false};
        this.authenticate = this.authenticate.bind(this);
	}

    authenticate() {
        this.setState({picker: true});
    }

    render() {
        return <>
            {layout(<h5>Your session has expired due to your inactivity. <button onClick={this.authenticate}>Click to authenticate.</button></h5>)}
            {this.state.picker ? <AuthMethodPicker /> : null};
        </>;
    }
}

export class LoadingProblem extends React.Component<{}, {}> {
    render() {
        return layout(<h5>Unable to load the application. <a href=".">Click to try it again.</a></h5>);
    }
}