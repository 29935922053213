import { FlowTemplate } from "../templates/Domain";
import _ from 'lodash';

export class Document {
    id: string;
    data: Array<IKeyValue>;

    constructor(data? : any) {
        this.id = data.id;
        this.data = data.data;
	}
}

export interface IKeyValue {
    key: string;
    value: any;
}

export class SourcesForAccumulate {
    flow: Flow;
    sources: FlowDocument[][];

    constructor(data? : any) {
        this.flow = new Flow(data.flow);
        this.sources = data.sources.map((optionSources : string[]) => optionSources.map(source => this.flow.getDocument(source)));
    }
}

export class Flow {
    _id: string;
    flowTemplateId: string;
    accountId: string;
    creationTimestamp: Date;
    steps: {[stepIndex : number]: FlowStep};
    documents: Array<FlowDocument>;
    state: string;
    flowTemplate : FlowTemplate;
    partner : Partner;

    constructor(data? : any) {
        this._id = data.id;
        this.flowTemplateId = data.flowTemplateId;
        this.accountId = data.accountId;
        this.creationTimestamp = data.creationTimestamp;
        this.steps = Object.entries(data.steps || {}).reduce((a, [k,v]) => ({...a, [Number(k)]: new FlowStep(v, Number(k), this)}), {});
        this.documents = data.documents.map((d : any) => new FlowDocument(d));
        this.state = data.state;
        this.flowTemplate = data.flowTemplate;
        this.partner = new Partner(data.partner);
    }

    getDocument(id: string) : FlowDocument | undefined {
        let documents = this.documents.filter(d => d.documentId === id);
        return documents && documents.length === 1 ? documents[0] : undefined;
    }

    getDocumentInBranch(stepIndex: number, closestDocument : FlowDocument) : FlowDocument | undefined {
        if (closestDocument.stepIndex < stepIndex)
            return undefined;
        let documents = [closestDocument];
        while (!!documents.length && documents.map(d => d.stepIndex).indexOf(stepIndex) === -1) {
            documents = (closestDocument.parentIds || []).map(pid => this.getDocument(pid)).filter(d => !!d) as FlowDocument[];
        }
        return documents.find(d => d.stepIndex === stepIndex);
    }

    getDocuments(stepIndex : number) : FlowDocument[] {
        return this.documents.filter(d => d.stepIndex === stepIndex);
    }

    getLeaves() {
        let nonLeafIds = this.documents.flatMap(d => d.parentIds);
        return this.documents.filter(d => nonLeafIds.indexOf(d.documentId) === -1);
    }

    getChildren(documentId : string) : FlowDocument[] {
        return this.documents.filter(d => d.parentIds.indexOf(documentId) !== -1);
    }

    getRoot() : FlowDocument {
        return this.documents.find(fd => fd.stepIndex === 0)!;
    }
}

export class FlowStep {
    options : FlowStepOption;
    stepIndex : number;
    flow : Flow;

    constructor(data : any, stepIndex : number, flow : Flow) {
        this.options = new FlowStepOption(data.options);
        this.stepIndex = stepIndex;
        this.flow = flow;
    }
}

export class FlowStepOption {
    available: Array<number>;

    constructor(data? : any) {
        this.available = data.available || [];
    }
}

export class Partner {
    id: string;

    constructor(data? : any) {
        this.id = data?.id;
    }
}

export class FlowDocument {
    documentId: string;
    documentTemplateId: string;
    creationType: string;
    creationOption: string;
    state: string;
    stepIndex: number;
    parentIds: Array<string>;
    availableOptions: Array<string>;
    exposedFields: {[k : string]: any};
    tracksFlow: string;
    trackedByFlows: string[];

    constructor(data? : any) {
        this.documentId = data.documentId;
        this.documentTemplateId = data.documentTemplateId;
        this.creationType = data.creationType;
        this.creationOption = data.creationOption;
        this.state = data.state;
        this.stepIndex = data.stepIndex;
        this.parentIds = data.parentIds;
        this.availableOptions = data.availableOptions;
        this.exposedFields = data.exposedFields;
        this.tracksFlow = data.tracksFlow;
        this.trackedByFlows = data.trackedByFlows;
    }

    isFinished() : boolean {
        return this.state === "FINISHED";
    }

    isDraft() : boolean {
        return this.state === "DRAFT";
    }

    isProcessing() : boolean {
        return this.state === "PROCESSING";
    }

    canRedo() : boolean {
        return this.availableOptions?.indexOf("REDO") > -1;
    }

    getAncestors (flow : Flow) : FlowDocument[] {
        let temp : FlowDocument[] = [this];
        let branch : FlowDocument[] = [this];

        while (temp.length) {
            temp = temp.flatMap(fd => fd.parentIds.map(id => flow.getDocument(id)!));
            branch.push(...temp);
        }
        return branch;
    }

    getParents (flow : Flow) : FlowDocument[] {
        return this.parentIds.map(parentId => flow.getDocument(parentId)!);
    }

    getChildren(flow : Flow, requiredStepIndex? : number) : FlowDocument[] {
        return flow.documents.filter(d =>
            d.parentIds.indexOf(this.documentId) > -1 && (requiredStepIndex === undefined || requiredStepIndex === d.stepIndex));
    }

    getDescendants (flow : Flow) : FlowDocument[] {
        let children: FlowDocument[] = flow.documents.filter(document => document.parentIds.includes(this.documentId));
        return children.reduce((descendants: FlowDocument[], document) => descendants.concat(document.getDescendants (flow)), children);
    }

    destinationFlowId(flowId : string) : string {
        return this.tracksFlow == null ? flowId : this.tracksFlow
    }
}

export interface ImportedData {
    data : IKeyValue[];
}

export class FlowCounters {
    unreadOrders: number;
    unsentOrderresponses: number;
    constructor(data? : any) {
        this.unreadOrders = data.unreadOrders;
        this.unsentOrderresponses = data.unsentOrderresponses;
    }
}

export interface LockingInfo {
    authName: string;
    created: Date;
}

export class Attachment {
    id : string;
    filename : string;

    constructor(data? : any) {
        this.id = data.id;
        this.filename = data.filename;
    }
}

export class DocumentRange {
    id : string;
    accountId : string;
    name : string;
    number : number;

    constructor(data? : any) {
        this.id = data.id;
        this.name = data.name;
        this.accountId = data.accountId;
        this.number = data.number;
    }
}

export const parseAttachmentString = (attachmentString : string) : {attachmentId : string} => {
    if (!attachmentString.match(/\{\{(\w+=\w+)(,\w+=\w+)*\}\}/)) {
        throw new Error();
    }
    const attachmentId =  _.trim(attachmentString, "{}").split(",").find(s => s.startsWith("attachmentId="))?.substring("attachmentId=".length);
    if (!attachmentId) {
        throw new Error("no attachment id");
    }
    return {attachmentId};
}
